// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// ----
import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// CSS
import 'scss/site'
// JS
import('js/site')

Rails.start()
ActiveStorage.start()
// ----
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
// require("@rails/ujs").start()
// require("@rails/activestorage").start()
// require("channels")


// ----
